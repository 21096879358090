export const routes = [
    {
        path: '/plans',
        name: 'plans.browse',
        component: () => import(/* webpackChunkName: "PlansBrowse" */ '@/views/app/PlansBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/plans/:id/clone',
        name: 'plans.clone',
        component: () => import(/* webpackChunkName: "PlansActions" */ '@/views/app/PlansActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/plans/create',
        name: 'plans.create',
        component: () => import(/* webpackChunkName: "PlansActions" */ '@/views/app/PlansActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/plans/:id/edit',
        name: 'plans.edit',
        component: () => import(/* webpackChunkName: "PlansActions" */ '@/views/app/PlansActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/plans/:id/delete',
        name: 'plans.delete',
        component: () => import(/* webpackChunkName: "PlansActions" */ '@/views/app/PlansActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/plans/:id/show',
        name: 'plans.show',
        component: () => import(/* webpackChunkName: "PlansActions" */ '@/views/app/PlansActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]